import type { AxiosResponse } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_EXPORT = "/v1/import";

export async function exportData(): Promise<AxiosResponse<Blob>> {
  return painelApi.get(`${PATH_V1_EXPORT}/exportUser`, {
    responseType: "blob",
    headers: {
      "Content-Disposition": 'attachment; filename="painel_user.sql"',
    },
  });
}

export async function exportDataAcessos(): Promise<AxiosResponse<Blob>> {
  return painelApi.get(`${PATH_V1_EXPORT}/exportAcessos`, {
    responseType: "blob",
    headers: {
      "Content-Disposition": 'attachment; filename="painel_acessos.sql"',
    },
  });
}
