import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { FC, useState } from "react";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { exportData, exportDataAcessos } from "../../services/exportar";

const HomeExportar: FC = () => {
  const [exportLoading, setExportLoading] = useState<boolean>(false);

  const handleExport = async () => {
    setExportLoading(true);
    try {
      const response = await exportData();
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "painel_user.sql");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      toast.success("Arquivo exportado com sucesso!");
    } catch (error: any) {
      toast.error("Erro ao exportar arquivo: " + error.message);
    } finally {
      setExportLoading(false);
    }
  };

  const handleExportAcessos = async () => {
    setExportLoading(true);
    try {
      const response = await exportDataAcessos();
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "painel_acessos.sql");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      toast.success("Arquivo exportado com sucesso!");
    } catch (error: any) {
      toast.error("Erro ao exportar arquivo: " + error.message);
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Exportar arquivo .sql</Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleExport}
                disabled={exportLoading}
              >
                {exportLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Download painel_user.sql"
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleExportAcessos}
                disabled={exportLoading}
              >
                {exportLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Download painel_acessos.sql"
                )}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleExport}
                disabled={exportLoading}
              >
                {exportLoading ? (
                  <CircularProgress size={24} />
                ) : (
                  "Download painel_geral.sql"
                )}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeExportar;
