import BoltIcon from "@mui/icons-material/Bolt";
import ChangeCircle from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { Card, Box, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { AxiosError } from "axios";
import {
  addDays,
  format,
  isValid,
  parse,
  startOfDay,
  isBefore,
} from "date-fns";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAuth from "../hooks/useAuth";
import {
  atualizarListaDeUsuarios,
  bloquearListaDeUsuariosHierarquia,
} from "../services/usuario";
import { ListagemUsuario, AxiosResponse, Response } from "../types";
import ConfirmDialog from "./confirmDialog";
import RenewDialog from "./renewDialog";

interface MuiTableProps {
  items: ListagemUsuario[];
  onDelete: (id: string) => Promise<void>;
  onSelectionChange?: (selectedIDs: string[]) => void;
}

export const MuiTable: React.FC<MuiTableProps> = ({
  items,
  onDelete,
  onSelectionChange,
}) => {
  const navigate = useNavigate();
  const { user, signout, setEditUser } = useAuth();
  const [selectedIDs, setSelectedIDs] = React.useState<GridRowSelectionModel>(
    [],
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmRenewOpen, setConfirmRenewOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
  const [rowDataToRenew, setRowDataToRenew] = useState<ListagemUsuario | null>(
    null,
  );
  const [dateFromToday, setDateFromToday] = useState<string>("");
  const [dateFromExpiration, setDateFromExpiration] = useState<string>("");

  const handleEditClick = (rowData: ListagemUsuario) => {
    const userToEdit = {
      idUsuario: rowData.id,
      nome: rowData.nome,
      dataVencimento: "",
      tipo: rowData.tipoUsuario,
    };
    setEditUser(userToEdit);
    //navigate(`/usuario/${rowData.tipoUsuario}/${rowData.id}`);
    navigate(`/usuario/editar`);
  };

  const handleEditFast = (rowData: ListagemUsuario) => {
    const userToEdit = {
      idUsuario: rowData.id,
      nome: rowData.nome,
      dataVencimento: "",
      tipo: rowData.tipoUsuario,
    };
    setEditUser(userToEdit);
    navigate(`/usuario/editarRapido`);
  };

  const handleRenewClick = (rowData: ListagemUsuario) => {
    setRowDataToRenew(rowData);

    const today = new Date();
    const expirationDate = rowData.dataVencimento
      ? parse(rowData.dataVencimento, "dd/MM/yyyy", new Date())
      : today;

    if (!isValid(expirationDate)) {
      toast.error("Data de vencimento inválida.");
      return;
    }

    setDateFromToday(format(addDays(today, 30), "dd/MM/yyyy"));
    setDateFromExpiration(format(addDays(expirationDate, 30), "dd/MM/yyyy"));

    setConfirmRenewOpen(true);
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel,
  ) => {
    setSelectedIDs(newSelectionModel);
    if (onSelectionChange) {
      onSelectionChange(newSelectionModel.map((id) => id.toString())); // Passa os IDs selecionados como strings
    }
  };

  const handleDeleteClick = (id: string) => {
    setUserIdToDelete(id);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (userIdToDelete) {
      await onDelete(userIdToDelete);
      setConfirmOpen(false);
      setUserIdToDelete(null);
    }
  };

  const handleUpdateUserType = async (rowData: ListagemUsuario) => {
    const updatedUser: ListagemUsuario = {
      ...rowData,
      tipoUsuario: "USUARIO",
    };

    atualizarListaDeUsuarios([updatedUser])
      .then((response: AxiosResponse<Response>) => {
        if (response) {
          toast.success("Atualizado com sucesso!");
          navigate(0);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (
          error.response &&
          error.response.data &&
          error.response.data.response
        ) {
          errorMessage += error.response.data.response;
        } else {
          errorMessage += error.message;
        }
        toast.error(errorMessage);
      });
  };

  const handleUpdate30days = (rowData: ListagemUsuario) => {
    handleRenewClick(rowData);
    setConfirmRenewOpen(true);
  };

  const confirmRenew = async (fromToday: boolean) => {
    if (!rowDataToRenew) return;

    setLoading(true);
    try {
      let currentDate: Date;

      if (fromToday) {
        currentDate = new Date();
      } else if (rowDataToRenew.dataVencimento) {
        currentDate = parse(
          rowDataToRenew.dataVencimento,
          "dd/MM/yyyy",
          new Date(),
        );
        if (!isValid(currentDate)) {
          toast.error("Data de vencimento inválida.");
          setLoading(false);
          setConfirmRenewOpen(false);
          return;
        }
      } else {
        currentDate = new Date();
      }

      const newDate = addDays(currentDate, 30);
      const formattedNewDate = format(newDate, "dd/MM/yyyy");

      const updatedUser: ListagemUsuario = {
        ...rowDataToRenew,
        tipoUsuario: "USUARIO",
        dataVencimento: formattedNewDate,
        bloqueado: "N",
      };

      const response: AxiosResponse<Response> = await atualizarListaDeUsuarios([
        updatedUser,
      ]);
      if (response) {
        toast.success("Renovado com sucesso!");
        navigate(0);
      }
    } catch (error) {
      let errorMessage = "Ocorreu um erro inesperado.";

      if (error instanceof AxiosError) {
        if (error.response) {
          if (error.response.data && error.response.data.response) {
            errorMessage = error.response.data.response;
          }
          if (error.response.status === 401) {
            signout();
            navigate("/");
          }
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setConfirmRenewOpen(false);
    }
  };

  const handleBloquearHierarquia = async (
    rowData: ListagemUsuario,
    acao: "S" | "N",
  ) => {
    const alteredUsers: ListagemUsuario[] = [];

    const newUser: ListagemUsuario = {
      id: rowData.id,
      tipoUsuario: rowData.tipoUsuario,
      perfil: rowData.perfil,
      cadastradoPor: rowData.cadastradoPor,
      usuario: rowData.usuario,
      nome: rowData.nome,
      dataVencimento: rowData.dataVencimento,
      bloqueado: rowData.bloqueado,
    };

    alteredUsers.push(newUser);

    try {
      const response = await bloquearListaDeUsuariosHierarquia(
        alteredUsers,
        acao,
      );
      if (response) {
        toast.success(response.data.response);
        navigate(0);
      }
    } catch (error: any) {
      if (error.response && error.response.data) {
        toast.error(error.response.data.response);
      } else {
        toast.error("Erro ao bloquear usuários e hierarquia.");
      }
    }
  };

  const columns: GridColDef[] = [
    { field: "nome", headerName: "Nome", width: 150 },
    { field: "usuario", headerName: "Usuário", width: 150 },
    { field: "perfil", headerName: "Perfil", width: 150 },
    {
      field: "tipoUsuario",
      headerName: "Tipo",
      width: 120,
      renderCell: (params) => {
        let color;
        switch (params.value) {
          case "ADMIN":
            color = "indigo";
            break;
          case "REVENDA":
            color = "blue";
            break;
          case "USUARIO":
            color = "teal";
            break;
          case "TESTE":
            color = "grey";
            break;
          default:
            color = "black";
        }

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "white",
                borderRadius: 10,
                textAlign: "center",
                backgroundColor: color,
                width: 72,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {params.value}
            </Box>
          </div>
        );
      },
    },
    { field: "cadastradoPor", headerName: "Cadastrado Por", width: 125 },
    {
      field: "dataVencimento",
      headerName: "Vencimento",
      width: 120,
      sortComparator: (v1, v2) => {
        const date1 = parse(v1, "dd/MM/yyyy", new Date());
        const date2 = parse(v2, "dd/MM/yyyy", new Date());
        return date1.getTime() - date2.getTime();
      },
    },
    {
      field: "bloqueado",
      headerName: "Bloqueado",
      width: 130,
      renderCell: (params) => {
        const dataVencimento = params.row.dataVencimento;
        const hoje = startOfDay(new Date()); // Zera a hora da data atual

        // Zera a hora da data de vencimento
        const vencimentoDate = dataVencimento
          ? startOfDay(parse(dataVencimento, "dd/MM/yyyy", new Date()))
          : null;

        let isEsgotado = false;

        if (vencimentoDate) {
          // Se não está bloqueado e a data de vencimento já passou (antes de hoje)
          if (params.value === "N" && isBefore(vencimentoDate, hoje)) {
            isEsgotado = true;
          }
        }

        let backgroundColor = "green";
        let text = "DESBLOQUEADO";

        if (isEsgotado) {
          backgroundColor = "orange";
          text = "ESGOTADO";
        } else if (params.value === "S") {
          backgroundColor = "#FA3F17";
          text = "BLOQUEADO";
        }

        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "white",
                borderRadius: 10,
                textAlign: "center",
                backgroundColor: backgroundColor,
                width: 120,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {text}
            </Box>
          </div>
        );
      },
    },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 200,
      renderCell: (params) => {
        return [
          <Tooltip
            title="Editar Rápido"
            arrow
            key={`${params.row.id}-quick-edit-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-quick-edit`}
              icon={<BoltIcon />}
              label="Editar Rápido"
              color="success"
              onClick={() => handleEditFast(params.row)}
            />
          </Tooltip>,
          <Tooltip
            title="Editar Completo"
            arrow
            key={`${params.row.id}-edit-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-edit`}
              icon={<EditIcon />}
              label="Editar"
              className="textPrimary"
              color="inherit"
              onClick={() => handleEditClick(params.row)}
            />
          </Tooltip>,
          <Tooltip
            title="Bloquear"
            arrow
            key={`${params.row.id}-bloquear-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-bloquear`}
              icon={<LockIcon />}
              label="Bloquear"
              onClick={() => handleBloquearHierarquia(params.row, "S")}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip
            title="Desbloquear"
            arrow
            key={`${params.row.id}-desbloquear-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-desbloquear`}
              icon={<LockOpenIcon />}
              label="Desbloquear"
              onClick={() => handleBloquearHierarquia(params.row, "N")}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip
            title="Excluir"
            arrow
            key={`${params.row.id}-delete-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-delete`}
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => handleDeleteClick(params.id.toString())}
              color="inherit"
            />
          </Tooltip>,
          params.row.tipoUsuario === "USUARIO" && (
            <Tooltip title="Renovar 30 dias">
              <GridActionsCellItem
                key={`${params.row.id}-renovar`}
                icon={<ChangeCircle />}
                label="Renovar"
                onClick={() => handleUpdate30days(params.row)}
                color="inherit"
              />
            </Tooltip>
          ),
          params.row.tipoUsuario === "TESTE" && (
            <Tooltip title="Alterar para Usuário">
              <GridActionsCellItem
                key={`${params.row.id}-update`}
                icon={<ChangeCircle />}
                label="Atualizar"
                onClick={() => handleUpdateUserType(params.row)}
                color="inherit"
              />
            </Tooltip>
          ),
        ];
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          checkboxSelection
          pagination
          rowSelectionModel={selectedIDs}
          onRowSelectionModelChange={handleSelectionModelChange}
        />
      </div>
      <ConfirmDialog
        isOpen={confirmOpen}
        title="Confirmar Exclusão"
        content="Tem certeza que deseja excluir? Todas as revendas e usuários abaixo serão excluídos!"
        onConfirm={handleConfirmDelete}
        onCancel={() => setConfirmOpen(false)}
      />
      <RenewDialog
        isOpen={confirmRenewOpen}
        dateFromToday={dateFromToday}
        dateFromExpiration={dateFromExpiration}
        onConfirm={confirmRenew}
        onCancel={() => setConfirmRenewOpen(false)}
      />
    </Card>
  );
};
